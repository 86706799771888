@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body{
    margin: 30px;
    height: 100%;
    font-family: 'Poppins', sans-serif;
}
.tabBtn{
    font-size: medium;
    color: #4d4d4d;
    border: none;
    width: Auto;
    height: 30px;
    border-radius: 10px;
    margin-right: 5px;
    background-color: rgba(191,229,215);
}
.spotify{
    float: right;
    border-radius: 15px;
}
.slidesWrapper{
    margin-top: 50px;
    padding-left: 20px;
    margin-bottom: 30px;
    text-align: center;
}
.App {
    width: 100%;
  }
  /*we used flexbox to position chat messages to the bottom of chat window
  but flexbox dont allow overflow scroll out of the box
  */
  .box {
    height: 70vh;
    margin-bottom: 10px;
    background-color: RGB(0,0,0,5%);
    overflow-y: auto;
    border-radius: 20px;
 
  }
  .box .inner {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-basis: 100%;
    height: 100%;
    align-content: flex-start;
    margin: 30px;

    /* min-height: min-content; handles overflow scroll for flex
    https://stackoverflow.com/questions/21515042/scrolling-a-flexbox-with-overflowing-content
    */
    min-height: -webkit-min-content;
    min-height: min-content;
    
  }
  .box .inner .message {
    background-color: #FFFBE0;
    padding: 2px 4px;
    margin: 2px;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 15px;


  }
  .box .reference {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
  }
  /* input {
    border-radius: 3px;
    width: 90%;
    padding: 10px;
    box-shadow: none;
    background: none;
    border: 1px solid #ccc;
  } */
  .myIcon {
    text-align: center;
    margin: 5px;
    margin-right: 20px;
    font-size: 20px;
}
.form-control{
    border-radius: 20px;
}
.wrapperQA{
    display: block;
    overflow: hidden;
    border-radius: 50px;
    transform: translateZ(0px);
    text-align: center;
    
}
.wrapperSlide{
    display: block;
    overflow: hidden;
    transform: translateZ(0px);
    /* border: 3px solid #BFE5D7; */
}

.layer1{
    grid-column: 1;
    grid-row: 1;
    margin-top: 20px;
  }

.single-update input {
    width: 400px;
    border-radius: 15px;
    border: 0;
    height: 50px;
    color: #696969;
    padding: 10px;
    display: block;
    margin-top: -30px;
}

.single-update textarea {
    border-radius: 15px;
    border: 0;
    color: #696969;
    padding: 10px;
    display: block;
}

.single-update button {
    font-size: medium;
    color: #4d4d4d;
    border: none;
    width: Auto;
    height: 30px;
    border-radius: 10px;
    margin-right: 5px;
    background-color: rgba(191,229,215);
}
.view-component button {
    font-size: medium;
    color: #4d4d4d;
    border: none;
    width: Auto;
    height: 30px;
    border-radius: 10px;
    margin-right: 5px;
    background-color: rgba(191,229,215);
    margin-bottom: 15px;
}
body {
    height: 100vh;
    background-image: linear-gradient(to bottom right, #d9f2e6, #ffffe0);
}
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}
.form-box input {
    background: rgba(255,255,255, 0.6);
    text-align: center;
    border: none;
    border-radius: 10px;
    display: block;
    outline: none;
    width: 400px;
    height: 30p;
    top: 250px;
    margin: 10px auto;
    padding: 10px;
    color: #a6a6a6;
}

.form-box label {
    font-size: larger;
    color: #4d4d4d;
}

.form-box button {
    font-size: larger;
    color: #4d4d4d;
    border: none;
    width: 200px;
    height: 50px;
    border-radius: 10px;
}

.form-box {
    text-align: center;
    background-color: rgba(191,229,215, 0.3);
    width: 600px;
    margin: 200px auto;
    padding: 30px 0 30px 0;
    border-radius: 20px;
}
