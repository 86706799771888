.form-box input {
    background: rgba(255,255,255, 0.6);
    text-align: center;
    border: none;
    border-radius: 10px;
    display: block;
    outline: none;
    width: 400px;
    height: 30p;
    top: 250px;
    margin: 10px auto;
    padding: 10px;
    color: #a6a6a6;
}

.form-box label {
    font-size: larger;
    color: #4d4d4d;
}

.form-box button {
    font-size: larger;
    color: #4d4d4d;
    border: none;
    width: 200px;
    height: 50px;
    border-radius: 10px;
}

.form-box {
    text-align: center;
    background-color: rgba(191,229,215, 0.3);
    width: 600px;
    margin: 200px auto;
    padding: 30px 0 30px 0;
    border-radius: 20px;
}