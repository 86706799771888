.App {
    width: 100%;
  }
  /*we used flexbox to position chat messages to the bottom of chat window
  but flexbox dont allow overflow scroll out of the box
  */
  .box {
    height: 70vh;
    margin-bottom: 10px;
    background-color: RGB(0,0,0,5%);
    overflow-y: auto;
    border-radius: 20px;
 
  }
  .box .inner {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-basis: 100%;
    height: 100%;
    align-content: flex-start;
    margin: 30px;

    /* min-height: min-content; handles overflow scroll for flex
    https://stackoverflow.com/questions/21515042/scrolling-a-flexbox-with-overflowing-content
    */
    min-height: min-content;
    
  }
  .box .inner .message {
    background-color: #FFFBE0;
    padding: 2px 4px;
    margin: 2px;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 15px;


  }
  .box .reference {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
  }
  /* input {
    border-radius: 3px;
    width: 90%;
    padding: 10px;
    box-shadow: none;
    background: none;
    border: 1px solid #ccc;
  } */
  .myIcon {
    text-align: center;
    margin: 5px;
    margin-right: 20px;
    font-size: 20px;
}
.form-control{
    border-radius: 20px;
}